import { TOGGLE_MENU } from './constants';

export function layout(state = {menu: true}, action) {
  if(action.type === TOGGLE_MENU){
    state = {
      ...state,
      menu: !state.menu
    }
  }
  return state;
}
