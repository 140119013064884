import { userConstants } from './constants';

const initialState = {
  loggedIn: false,
  checkingIn: true,
  loggingIn: false,
  errors: [],
  user: {},
  modules: [],
  error: undefined,
}

export function auth(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        errors: [],
        loggedIn: true,
        loggingIn: false,
        user: action.user,
        modules: action.user.modules,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        errors: action.errors,
        user: {},
        modules: [],
      };
    case userConstants.CHECK_REQUEST:
      return {
        ...state,
        checkingIn: true,
        user: {},
        modules: [],
      };
    case userConstants.CHECK_SUCCESS:
      return {
        ...state,
        errors: [],
        loggedIn: true,
        checkingIn: false,
        user: action.user,
        modules: action.modules,
      };
    case userConstants.CHECK_FAILURE:
      return {
        ...state,
        loggedIn: false,
        checkingIn: false,
        error: action.error,
        user: {},
        modules: [],
      };
    case userConstants.PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true,
        profile: {},
      };
    case userConstants.PROFILE_SUCCESS:
      return {
        ...state,
        errors: [],
        profileLoading: false,
        profile: action.payload,
      };
    case userConstants.PROFILE_FAILURE:
      return {
        ...state,
        profileLoading: false,
        error: action.error,
        profile: {},
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        errors: [],
        loggedIn: false,
        user: {},
        modules: [],
      };
    default:
      return state
  }
}
