import styled from 'styled-components';

const DataListPage = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;
  height: 100%;
  width: 100%;
  
  .toolbar {
    display: flex;
    flex-flow: row;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    border-bottom: 1px solid #edeef0;
  }
  
  .filter {
    width: 150px;
  }
`;

export default DataListPage;
