import { expensesConstants } from './constants';

export function expenses(state = {
  businessExpenseDialog: false,
  travelExpenseDialog: false,
  loading: false,
  records: [],
  monthsPicker: false,
  expensesLoading: false,
  expenses: [],
  businessExpense: [],
}, action) {

  switch (action.type) {
    case expensesConstants.BUSINESS_EXPENSE_DIALOG:
      return {
        ...state,
        businessExpenseDialog: !state.businessExpenseDialog
      };
    case expensesConstants.TRAVEL_EXPENSE_DIALOG:
      console.log(state);
      return {
        ...state,
        travelExpenseDialog: !state.travelExpenseDialog
      };
    case expensesConstants.GET_EXPENSES_REQUEST:
      return {
        ...state,
        loading: true,
        records: [],
      };
    case expensesConstants.GET_EXPENSES_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload,
      };
    case expensesConstants.GET_EXPENSES_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case expensesConstants.GET_EXPENSES_LIST_REQUEST:
      return {
        ...state,
        expensesLoading: true,
        expenses: [],
      };
    case expensesConstants.GET_EXPENSES_LIST_SUCCESS:
      return {
        ...state,
        expensesLoading: false,
        expenses: action.payload,
      };
    case expensesConstants.GET_EXPENSES_LIST_FAILURE:
      return {
        ...state,
        expensesLoading: false,
        errors: action.payload,
      };
    case expensesConstants.TOGGLE_MONTHS_PICKER:
      return {
        ...state,
        monthsPicker: !state.monthsPicker,
      };
    default:
      return state
  }

}
