import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  .expenses-popover {
  
    &.bp3-popover-content-sizing {
      .bp3-popover-content {
        padding: 10px;
      }
    }
  
    .bp3-button {
      background-image: none;
      width: 100%;
      
      &:first-child {
        margin-bottom: 5px;
      }

      &.business {
        background-color: #7087c9;
        color: #fff;
      }
      
      &.travel {
        background-color: #6db7e8;
        color: #fff;
      }
    }
  }

  .root {
    display: flex;
    flex-flow: column;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  main {
    width: 100%;
    height: 100%;
  }
  
  html {
    overflow: hidden;
  }
  
  body {
    overflow: auto;
  }
  
  a {
    &:hover {
      text-decoration: none;
    }
  }
  
  .bp3-control.bp3-checkbox input:checked~.bp3-control-indicator:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E")
  }
  
  ul {
    list-style: none;
  }
  

`;
export default GlobalStyle;