import { salesInvoiceConstants } from './constants';

const initialState = {
  records: [],
  loading: false,
}

export function invoices(state = initialState, action) {
  switch (action.type) {
    case salesInvoiceConstants.INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
        records: []
      };
    case salesInvoiceConstants.INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload
      };
    case salesInvoiceConstants.INVOICES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        records: []
      };
    default:
      return state
  }

}