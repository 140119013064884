import { payslipsConstants } from './constants';

const initialState = {
  records: [],
  loading: false,
}

export function payslips(state = initialState, action) {
  switch (action.type) {
    case payslipsConstants.PAYSLIPS_REQUEST:
      return {
        ...state,
        loading: true,
        records: []
      };
    case payslipsConstants.PAYSLIPS_SUCCESS:
      //format date field
      const records = action.payload.map(record => ({
        ...record,
        // date: moment(record.date,'YYYY-MM-DD HH:mm:ss').utcOffset(0, true).format('DD/MM/YYYY HH:mm:ss'),
      }));

      return {
        ...state,
        loading: false,
        records,
      };
    case payslipsConstants.PAYSLIPS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        records: []
      };
    default:
      return state
  }

}