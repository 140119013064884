export const businessExpensesConstants = {
  GET_BUSINESS_EXPENSE_REQUEST: 'BUSINESS_EXPENSES_GET_REQUEST',
  GET_BUSINESS_EXPENSE_SUCCESS: 'BUSINESS_EXPENSES_GET_SUCCESS',
  GET_BUSINESS_EXPENSE_FAILURE: 'BUSINESS_EXPENSES_GET_FAILURE',

  SAVE_BUSINESS_EXPENSE_REQUEST: 'BUSINESS_EXPENSES_SAVE_REQUEST',
  SAVE_BUSINESS_EXPENSE_SUCCESS: 'BUSINESS_EXPENSES_SAVE_SUCCESS',
  SAVE_BUSINESS_EXPENSE_FAILURE: 'BUSINESS_EXPENSES_SAVE_FAILURE',

  DELETE_BUSINESS_EXPENSE_REQUEST: 'DELETE_EXPENSES_SAVE_REQUEST',
  DELETE_BUSINESS_EXPENSE_SUCCESS: 'DELETE_EXPENSES_SAVE_SUCCESS',
  DELETE_BUSINESS_EXPENSE_FAILURE: 'DELETE_EXPENSES_SAVE_FAILURE',
};