import { bankStatementsConstants } from './constants';

const initialState = {
  records: [],
  loading: false,
}

export function bankStatements(state = initialState, action) {
  switch (action.type) {
    case bankStatementsConstants.BANK_STATEMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        records: []
      };
    case bankStatementsConstants.BANK_STATEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload
      };
    case bankStatementsConstants.BANK_STATEMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        records: []
      };
    default:
      return state
  }

}