import { dashboardConstants } from "./constants";

const initialState = {
  payslip: {
    total: 0,
    date: null,
  },
  working_hours: {
    total: 0,
    date: null,
  },
  invoice: {
    total: 0,
    date: null,
  },
  expenses: {
    total: 0,
    business: 0,
    travel: 0,
  },
  loading: true,
};

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        records: {}
      };
    case dashboardConstants.SUMMARY_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        expenses: action.payload.expenses,
        invoice: action.payload.invoice,
        working_hours: action.payload.working_hours,
        payslip: action.payload.payslip,
      };
    case dashboardConstants.SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        records: {}
      };
    default:
      return state
  }
}
