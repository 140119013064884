import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import { BaseCSS } from 'styled-bootstrap-grid';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from 'styled-components';
import { FocusStyleManager, Spinner } from "@blueprintjs/core";
import { GlobalStyle } from "./styles/index";
import store from "./store";

import * as serviceWorker from './serviceWorker';

FocusStyleManager.onlyShowFocusOnTabs();

const theme = {
  space: [ 0, 6, 12, 18, 24 ],
  breakpoints: [ '450px', '768px', '991px', '1400px', '1600px'],
  mainColor: '#313c54',
};

const App = lazy(() => import("./App"))

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <GlobalStyle whiteColor />
      <BaseCSS/>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
