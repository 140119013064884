import { workingHoursConstants } from './constants';

const initialState = {
  records: [],
  loading: false,
}

export function workingHours(state = initialState, action) {
  switch (action.type) {
    case workingHoursConstants.WORKING_HOURS_REQUEST:
      return {
        ...state,
        loading: true,
        records: []
      };
    case workingHoursConstants.WORKING_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload
      };
    case workingHoursConstants.WORKING_HOURS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        records: []
      };
    default:
      return state
  }

}