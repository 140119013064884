export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  CHECK_REQUEST: 'USERS_CHECK_REQUEST',
  CHECK_SUCCESS: 'USERS_CHECK_SUCCESS',
  CHECK_FAILURE: 'USERS_CHECK_FAILURE',

  PROFILE_REQUEST: 'PROFILE_LOGIN_REQUEST',
  PROFILE_SUCCESS: 'PROFILE_LOGIN_SUCCESS',
  PROFILE_FAILURE: 'PROFILE_LOGIN_FAILURE',
};
