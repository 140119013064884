export const expensesConstants = {
  GET_EXPENSES_REQUEST: 'EXPENSES_GET_REQUEST',
  GET_EXPENSES_SUCCESS: 'EXPENSES_GET_SUCCESS',
  GET_EXPENSES_FAILURE: 'EXPENSES_GET_FAILURE',

  GET_EXPENSES_LIST_REQUEST: 'EXPENSES_LIST_GET_REQUEST',
  GET_EXPENSES_LIST_SUCCESS: 'EXPENSES_LIST_GET_SUCCESS',
  GET_EXPENSES_LIST_FAILURE: 'EXPENSES_LIST_GET_FAILURE',

  BUSINESS_EXPENSE_DIALOG: 'BUSINESS_EXPENSE_DIALOG',
  TRAVEL_EXPENSE_DIALOG: 'TRAVEL_EXPENSE_DIALOG',

  TOGGLE_MONTHS_PICKER: 'TOGGLE_MONTHS_PICKER',

  GET_BUSINESS_EXPENSE_REQUEST: 'BUSINESS_EXPENSES_GET_REQUEST',
  GET_BUSINESS_EXPENSE_SUCCESS: 'BUSINESS_EXPENSES_GET_SUCCESS',
  GET_BUSINESS_EXPENSE_FAILURE: 'BUSINESS_EXPENSES_GET_FAILURE',
};