import thunk from "redux-thunk";
import { applyMiddleware, combineReducers, createStore } from "redux";

import { toaster } from '../components/Toaster/Store/reducers';
import { layout } from '../components/Menu/Store/reducers';
import { auth } from '../pages/Auth/Store/reducers';
import { expenses } from '../pages/Expenses/Store/reducers';
import { businessExpense } from '../pages/Expenses/BusinessExpense/redux/reducers';
import { invoices } from '../pages/SalesInvoices/Store/reducers';
import { bankStatements } from '../pages/BankStatements/Store/reducers';
import { payslips } from '../pages/Payslips/Store/reducers';
import { workingHours } from '../pages/WorkingHours/Store/reducers';
import { dashboard } from '../pages/Dashboard/redux/reducer';

const middleware = applyMiddleware(thunk);

const Reducers = combineReducers({
  auth,
  toaster,
  layout,
  expenses,
  businessExpense,
  invoices,
  bankStatements,
  payslips,
  workingHours,
  dashboard,
});

const Store= createStore(Reducers, middleware);

export default Store;