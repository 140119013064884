import { businessExpensesConstants } from './constants';

export function businessExpense(state = {
  loading: false,
  record: {},
  saving: false,
}, action) {

  switch (action.type) {
    case businessExpensesConstants.GET_BUSINESS_EXPENSE_REQUEST:
      return {
        ...state,
        loading: true,
        record: [],
      };
    case businessExpensesConstants.GET_BUSINESS_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        record: action.payload,
      };
    case businessExpensesConstants.GET_BUSINESS_EXPENSE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case businessExpensesConstants.SAVE_BUSINESS_EXPENSE_REQUEST:
      return {
        ...state,
        saving: true,
      };
    case businessExpensesConstants.SAVE_BUSINESS_EXPENSE_SUCCESS:
      return {
        ...state,
        saving: false,
      };
    case businessExpensesConstants.SAVE_BUSINESS_EXPENSE_FAILURE:
      return {
        ...state,
        saving: false,
        errors: action.payload,
      };

    default:
      return state
  }

}
